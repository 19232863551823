<template>
  <div class="content_box">
    <div class="content_box_title">
      <div @click="returnPage"><i class="iconfont">&#xe625;</i>消息详情</div>
    </div>
    <div class="content_box_text">
      <div class="content_box_text_title">{{ list.title }}</div>
      <div class="content_box_text_name">
        <span class="text_name">{{ list.orgName }}</span>
        <span class="text_time">{{
          formatDate(list.createTime, "YYYY/MM/DD HH:mm")
        }}</span>
      </div>
    </div>
    <template v-if="list.type == 'test'">
      <div class="content_box_details" v-if="textTips">
        <div class="content_box_details_tips">
          <span class="tips_title">试卷名称</span>
          <span class="tips_content">{{ list.data[0].title }}</span>
        </div>
        <div class="content_box_details_tips">
          <span class="tips_title">考试时间</span>
          <span class="tips_content"
            >{{ formatDate(list.data[0].startTime, "MM/DD HH:mm") }} ~
            {{ formatDate(list.data[0].endTime, "MM/DD HH:mm") }}</span
          >
        </div>
        <div class="content_box_details_tips">
          <span class="tips_title">答题时长</span>
          <span class="tips_content">{{ list.data[0].duration }}分钟</span>
        </div>
        <ul class="content_box_details_list">
          <li
            v-for="(item, index) in list.data"
            :key="index"
            @click="startExamBut(item.testPaperId, item.timeTips, item.status)"
          >
            <img :src="src" alt />
            <div class="list_information">
              <div class="list_information_title">{{ item.className }}</div>
              <div class="list_information_label">
                <span class="list_information_label_one" v-if="item.timeTips"
                  >已结束</span
                >
                <span
                  class="list_information_label_one"
                  :style="item.status ? 'color:#0D79FF' : 'color:#FF3B3B'"
                  v-else
                  >{{ item.status ? "已完成" : "未完成" }}</span
                >
                <span class="list_information_label_two"
                  >共{{ item.classStudentCount }}人</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="content_box_details" v-else>该试卷已被删除,请联系老师</div>
    </template>
    <template v-else>
      <div class="content_box_details" v-html="list.content"></div>
    </template>

    <div class="content_box_popup">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="500px">
      <test-tip @close="close" :testPaperContent="testPaperContent" />
    </el-dialog>
    <el-dialog title="提示" :visible.sync="reDialogVisible" width="500px">
      <reexamination-tip @close="close" :testPaperContent="testPaperContent" />
    </el-dialog>
    </div>
  </div>
</template>

<script>
import { getMessageDetails } from "@/api/notice";
import { getTestInfo } from "@/api/test";
import { formatDate } from "@/utils/index";
import testTip from "../testTip";
import reexaminationTip from "../testTip/reexaminationTip";
export default {
  components: {
    testTip,
    reexaminationTip,
  },
  data() {
    return {
      formatDate,
      list: {},
      textTips: true, //判断试卷是否被删除
      src:
        "https://indong-study.oss-cn-shenzhen.aliyuncs.com/study/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20201112094331.png",
      dialogVisible: false,
      reDialogVisible: false,
      value: "",
      testPaperContent: {},
    };
  },
  created() {
    this.getParameter();
  },
  watch: {
    $route() {
      this.getParameter();
    },
  },
  methods: {
    //返回上一页
    returnPage() {
      this.$router.push({
        path: "myStudy",
        query: { option: 4, path: "message" },
      });
    },
    //获取页面参数
    getParameter() {
      let pageId = this.$route.query.id || "0";
      if (pageId) {
        getMessageDetails(pageId).then((res) => {
          if (res.code == 200) {
            if (res.data.data != null) {
              if (res.data.data.length != 0) {
                res.data.data.forEach((e) => {
                  e.timeTips = false;
                  if (
                    Date.parse(new Date(e.endTime)) < Date.parse(new Date())
                  ) {
                    e.timeTips = true;
                  }
                });
                this.textTips = true;
              } else {
                this.textTips = false;
              }
            }
            this.list = res.data;
          }
        });
      }
    },
    //点击班级试卷
    startExamBut(id, tips, status) {
      if (tips) {
        this.$message.error('该场考试已结束');
        return;
      }
      if (status) {
        this.reDialogVisible = true;
        this.getTestInfo(id);
      } else {
        this.dialogVisible = true;
        this.getTestInfo(id);
      }
    },
    // 关闭tip
    close() {
      this.dialogVisible = false;
      this.reDialogVisible = false;
    },
    // 获取试卷信息
    getTestInfo(id) {
      getTestInfo(id).then((json) => {
        console.log(json);
        const { code, data, msg } = json;
        if (code === 200) {
          this.testPaperContent = data;
        } else {
          this.$message.error(msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content_box {
  min-height: 748px;
}

.content_box_title {
  padding-left: 33px;
  border-bottom: 1px solid #eeeeee;
  text-align: left;
  & > div {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 60px;
    height: 60px;
    width: 110px;
    cursor: pointer;
    & > .iconfont {
      font-size: 18px;
      padding-right: 12px;
    }
  }
}

.content_box_text {
  padding: 0 49px 0 34px;
  text-align: left;
  & > .content_box_text_title {
    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    line-height: 20px;
  }
  & > .content_box_text_name {
    margin-top: 14px;
    font-size: 14px;
    font-weight: 500;
    color: #999999;
    line-height: 14px;
    & > .text_name {
      margin-right: 21px;
    }
  }
}

.content_box_details {
  margin: 33px 32px 33px 34px;
  text-align: left;
  overflow: hidden;
}

.content_box_details_tips {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  & > .tips_title {
    color: #999999;
    margin-right: 20px;
  }
  & > .tips_content {
    color: #333333;
  }
}

.content_box_details_list {
  padding: 0;
  margin-top: 38px;
  & > li {
    width: 208px;
    height: 202px;
    margin-right: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
    float: left;
    margin-bottom: 24px;
    cursor: pointer;
    & > img {
      width: 208px;
      height: 122px;
      border-radius: 2px;
    }
    & > .list_information {
      padding: 13px 15px 15px 14px;
      & > .list_information_title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 16px;
      }
      & > .list_information_label {
        margin-top: 21px;
        font-size: 12px;
        font-weight: 500;
        color: #999999;
        line-height: 12px;
        & > .list_information_label_one {
          float: left;
          // color: #FF3B3B;
        }
        & > .list_information_label_two {
          float: right;
          color: #999999;
        }
      }
    }
  }
  & > li:nth-child(4n) {
    margin-right: 0;
  }
}

//弹窗
.content_box_popup {
  text-align: left;
}

/deep/ .el-dialog__header {
  border-bottom: 1px solid #f2f2f2;
}

// /deep/ .el-input__inner {
//   border-radius: 40px !important;
// }
</style>